:root {
    --btn-green-background: rgba(179, 232, 129, 1);
    --btn-green-background-darker: rgba(169, 222, 119, 1);
    --btn-green-text: rgb(21, 42, 35);
}

body {
    min-height: 100vh;
    text-align: left;
    background-color: #fff;
    color: #4b595e;
}

.brand {
    padding-top: 7px;
    padding-bottom: 8px;
    text-align: left;
    display: flex;
}

.brand > img {
    height: 75px;
}

.login-wrapper {
    width: 300px;
    max-width: 100%;
    padding-top: 10px;
    padding-bottom: 24px;
    margin-top: auto !important;
    margin-bottom: auto !important;
    box-sizing: border-box;
}

.login-section-wrapper {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 68px 100px;
    border-radius: 6px;
    background-color: #fff;
    flex: 0 0 50%;
}

.login-wrapper .title {
    font-size: 30px;
    color: #000;
    font-weight: bold;
    margin-bottom: 25px;
}

.row {
    display: flex;
    height: 100vh;
}

.brand-title {
    font-weight: bold;
    padding: 0 0.5em;
}

*,
::after,
::before {
    box-sizing: border-box;
}

.login-btn {
    background-color: var(--btn-green-background);
    color: var(--btn-green-text);
    padding: 13px 20px;
    font-size: 20px;
}

.form-control:focus {
    border-color: var(--btn-green-background);
    -webkit-box-shadow: rgb(179 232 129 / 50%) 0px 0px 0px 0.3rem;
    box-shadow: rgb(179 232 129 / 50%) 0px 0px 0px 0.3rem;
}

a:hover {
    text-decoration: underline;
}

.no-acc {
    padding-top: 4em;
    font-size: 14px;
    font-weight: 400;
}

.no-acc > a {
    color: #4b595e;
}

.image-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    padding: unset;
}

.image-wrapper > img {
    width: 100%;
    height: 100vh;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: left;
    object-position: left;
}

.container-fluid {
    height: 100vh;
}

.container-fluid > .row {
    display: flex;
}

.lang {
    padding-top: 6px;
    width: fit-content;
    float: right;
}

.bootstrap-select .btn:focus {
    outline: none !important;
    border-color: rgba(179, 232, 129, 1);
    box-shadow: 0 0 10px rgba(179, 232, 129, 1);
}

.selectLang {
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    width: 75px;
    font-weight: bold;
}

.selectLang > option {
	text-transform: initial;
}